import React, { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"

import { Modal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons"

import CustomButton from "../../../generic/CustomButton"

const modal = {
  display: "flex: 0 1 auto;",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around"
}

export default function CreateEventModal({ show, onClose }) {
  const navigate = useNavigate()

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Create Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={modal}>
            <div
              className={` border p-3 d-flex flex-column align-items-center justify-content-center`}
            >
              <FontAwesomeIcon
                icon={faPen}
                color="blue"
                className="mb-3"
              ></FontAwesomeIcon>
              <h4 className="fw-bold">Start from scratch</h4>
              <p className="text-center">
                Add all your event details, create new tickets, and set up
                recurring events
              </p>
              <CustomButton onClick={() => navigate("/events/create")}>
                Create Event
              </CustomButton>
            </div>
            <div className="p-3"></div>
            <div
              className={`border p-3 d-flex flex-column align-items-center justify-content-center`}
            >
              <FontAwesomeIcon
                icon={faWandMagicSparkles}
                color="blue"
                className="mb-3"
              ></FontAwesomeIcon>
              <h4 className="fw-bold">Create my event faster with AI</h4>
              <p className="text-center">
                Answer a few quick questions to generate an event that's ready
                to publish almost instantly
              </p>
              <CustomButton onClick={() => navigate("/events/create-with-ai")}>
                Create with AI
              </CustomButton>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  )
}