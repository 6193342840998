import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function InlineLoader({size, className = ''}) {
    return <Spinner
        as={"span"}
        animation="border"
        role="status"
        size={size}
        aria-hidden={"true"}
        className={className}
    >
        <span className="visually-hidden">Loading…</span>
    </Spinner>;
}
