import { Pagination } from "react-bootstrap";
import LandingPageLayout from "../../../components/app/layouts/LandingPageLayout";
import NftCard from "../../../components/app/nft/NftCard";
import HeadTags from "../../../components/generic/HeadTags";
import styles from "../[id]/CollectibleDetailPage.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import openseaClient from "../../../utils/opensea_client";
import { showNotification } from "../../../utils/notifications_helper";

export default function CollectibleListingPage() {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [nftsData, setNftsData] = useState([]);

    useEffect(() => {
        openseaClient.get(`/collection/${slug}/nfts`).then(response => {
            setNftsData(response.data.nfts);
        }).catch(error => {
            console.log("error = ", error);
            showNotification("Error", "Failed to fetch data", "danger");
        });
    }, []);

    let newData=[
        {
            "id": 1,
            "name": "Open Social",
            "description": "49899's Follower",
            "image": "https://via.placeholder.com/315"
        },
        {
            "id": 2,
            "name": "Testing Collection",
            "description": "t2 | V3 Testing collection name",
            "image": "https://via.placeholder.com/315"
        },
        {
            "id": 3,
            "name": "BRETT",
            "description": "$BRETT : https://brett.cash",
            "image": "https://via.placeholder.com/315"
        },
        {
            "id": 4,
            "name": "Elvie Cat",
            "description": "Elvie Cat",
            "image": "https://via.placeholder.com/315"
        },{
          "id": 5,
          "name": "Open Social",
          "description": "49899's Follower",
          "image": "https://via.placeholder.com/315"
        },
        {
            "id": 6,
            "name": "Testing Collection",
            "description": "t2 | V3 Testing collection name",
            "image": "https://via.placeholder.com/315"
        },
        {
            "id": 7,
            "name": "BRETT",
            "description": "$BRETT : https://brett.cash",
            "image": "https://via.placeholder.com/315"
        },
        {
            "id": 8,
            "name": "Elvie Cat",
            "description": "Elvie Cat",
            "image": "https://via.placeholder.com/315"
        }
    ]

    return (
        <LandingPageLayout>
            <HeadTags title={`Collectibles Page`} />
            <div className="mwc">
                <div>
                    <h3 className="fw-bold mt-5 mb-4">All NFTs</h3>
                    {/* <FloatingInput
                    id={"search"}
                    label="Search by Events, Artists or User"
                    // onChange={e => setSearch(e.target.value)}
                    className="me-5x"
                    placeholder={`Search by Events, Artists or User`}
                    // value={search}
                    /> */}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className={styles.container}>
                        <div className={styles.grid}>
                            {nftsData.map(nft => (
                                <NftCard 
                                    nft={
                                        {
                                            id: nft.identifier,
                                            image: nft.image_url,
                                            name: nft.name,
                                            description: nft.description,
                                            onClick: () => navigate(`/collectibles/${nft.identifier}`, { state: { nft } })
                                        }
                                    }
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </LandingPageLayout>
    );
}