import React, { useEffect, useState } from "react"
import LandingPageLayout from "../../../components/app/layouts/LandingPageLayout"
import { Link, useLocation, useParams } from "react-router-dom"
import styles from "./CollectibleDetailPage.module.scss"
import { ReactComponent as IcLocation } from "../../../assets/ic-location.svg"
import { ReactComponent as IcChair } from "../../../assets/ic-chair.svg"
import { Badge, Button, Card, Col, Image, Row,Pagination } from "react-bootstrap"
import { useAxiosGet } from "../../../utils/axios_helper"
import FullPageOverlayLoader from "../../../components/generic/Loaders/FullPageOverlayLoader"
import ErrorBlock from "../../../components/generic/ValidationMessages/ErrorBlock"
import EventDisplayPicture from "../../../components/app/events/EventDisplayPicture"
import { getFormattedDateFromApi } from "../../../utils/constants"
import HeadTags from "../../../components/generic/HeadTags"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-regular-svg-icons"
import ReportNftModal from "../../../components/app/collectibles/ReportNftModal"
import OfferModal from "../../../components/app/collectibles/OfferModal"
import NftCard from "../../../components/app/nft/NftCard"
import FloatingInput from "../../../components/generic/InputFields/FloatingInput"
import openseaClient, { CHAIN } from "../../../utils/opensea_client"

export default function CollectibleDetailPage() {
  const location = useLocation();
  const { nft } = location.state || {};  
  console.log("nft = ", nft);
  

  const processing = false
  const errors = { detail: [] }

  const [showReportModal, setShowReportModal] = useState(false)
  const [showOfferModal, setShowOfferModal] = useState(false)
  const [nftDetailDate, setNftDetailDate] = useState(null)

  useEffect(() => {
    const fetchCollectionsNFT = async () => {
      try {
        const response = await openseaClient.get(`/chain/${CHAIN}/contract/${nft.contract}/nfts/${nft.identifier}`);
        setNftDetailDate(response.data.nft);
      } catch (error) {
        console.log("error = ", error);
      }
    }
    fetchCollectionsNFT()
  }, []);

  const data = {
    category: "Quantum Arts",
    title: "Bored Ape",
    creator: "Yuga Labs",
    owner: "Rhyme",
    likes: 352,
    cryptoPrice: "1,800",
    price: "1,500",
    cryptoHighestBid: "2,800",
    highestBid: "2,200",
    image:
      "https://media.licdn.com/dms/image/D4E12AQFLKmSeXoX9wQ/article-cover_image-shrink_720_1280/0/1690376030758?e=2147483647&v=beta&t=dhx_EN1ZW7E3cyzv5Vg24IK5f6URXearMdPXACJkvd8",
    circulerImage:
      "https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg?w=996&t=st=1718292526~exp=1718293126~hmac=d4552ba35c570dceb26867cc981043e593c8946f56615c0c593761550b7027e7",
    history: [
      {
        cryptoPrice: 2000,
        price: 1800,
        datetime: "46 minutes ago",
        address: "0x36e2...d3f1",
        image:
          "https://media.licdn.com/dms/image/D4E12AQFLKmSeXoX9wQ/article-cover_image-shrink_720_1280/0/1690376030758?e=2147483647&v=beta&t=dhx_EN1ZW7E3cyzv5Vg24IK5f6URXearMdPXACJkvd8"
      },
      {
        cryptoPrice: 2000,
        price: 1800,
        datetime: "46 minutes ago",
        address: "0x36e2...d3f1",
        image:
          "https://media.licdn.com/dms/image/D4E12AQFLKmSeXoX9wQ/article-cover_image-shrink_720_1280/0/1690376030758?e=2147483647&v=beta&t=dhx_EN1ZW7E3cyzv5Vg24IK5f6URXearMdPXACJkvd8"
      },
      {
        cryptoPrice: 2000,
        price: 1800,
        datetime: "46 minutes ago",
        address: "0x36e2...d3f1",
        image:
          "https://media.licdn.com/dms/image/D4E12AQFLKmSeXoX9wQ/article-cover_image-shrink_720_1280/0/1690376030758?e=2147483647&v=beta&t=dhx_EN1ZW7E3cyzv5Vg24IK5f6URXearMdPXACJkvd8"
      }
    ]
  }

  return (
    <LandingPageLayout>
       <HeadTags title={`All Events`} />
        {processing && <FullPageOverlayLoader />}
        <div className={`mwc pt-4`}>
          <ErrorBlock errors={errors.detail} />
        </div>
        {data && (
          <div className={`mwc pt-4`}>
            <Row>
              <Col md={6}>
                <div style={{ width: 575 }}>
                  <Image
                    fluid
                    src={nftDetailDate?.image_url}
                    alt={nftDetailDate?.name}
                    className="rounded-3"
                  />
                  <Button
                    variant="outline-primary"
                    className="mt-3"
                    onClick={() => setShowReportModal(true)}
                  >
                    Report NFT
                  </Button>
                </div>
              </Col>
              <Col md={6}>
                <div style={{ maxWidth: "576px" }}>
                  <div className="d-flex align-items-center gap-2">
                    <Image
                      fluid
                      style={{ width: "56px", height: "56px" }}
                      src={data.circulerImage}
                      className="rounded-circle"
                    />{" "}
                    <span className="fw-bold">{data.category}</span>
                  </div>
                  <div className={`fs-2 fw-bold color-black mt-3`}>
                    {nftDetailDate?.name}
                  </div>
                  <div className="d-flex gap-5 mt-3">
                    <div className="d-flex align-items-center gap-2">
                      <Image
                        fluid
                        style={{ width: "56px", height: "56px" }}
                        src={data.circulerImage}
                        className="rounded-circle"
                      />
                      <div>
                        <div className="fw-bold text-muted">Creator</div>
                        <span className="fw-bold">{nftDetailDate?.creator?.slice(0,6)}...</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <Image
                        fluid
                        style={{ width: "56px", height: "56px" }}
                        src={data.circulerImage}
                        className="rounded-circle"
                      />
                      <div>
                        <div className="fw-bold text-muted">Current Owner</div>
                        <div className="fw-bold">{nftDetailDate?.owners[0]?.address?.slice(0,6)}...</div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ borderTop: "1px solid rgba(0,0,0,0.5)" }} />
                  <div className="d-flex gap-2">
                    <FontAwesomeIcon icon={faHeart} className="fs-4" />{" "}
                    <span className="fw-bold">{data.likes}</span>
                  </div>
                  <Card className="mt-3 p-4">
                    <div className="d-flex">
                      <div style={{ flexBasis: "50%" }}>
                        <div className="small text-muted">Price</div>
                        <div className="fw-bold">{data.cryptoPrice} MATIC</div>
                        <div className="small text-muted">${data.price}</div>
                      </div>
                      <div style={{ flexBasis: "50%" }}>
                        <div className="small text-muted">Highest offer</div>
                        <div className="fw-bold">
                          {data.cryptoHighestBid} MATIC
                        </div>
                        <div className="small text-muted">${data.highestBid}</div>
                      </div>
                    </div>
                    <Button variant="primary" className="mt-3">
                      BUY NOW FOR 2,500 MATIC
                    </Button>
                    <Button
                      variant="outline-primary"
                      className="mt-3"
                      onClick={() => setShowOfferModal(true)}
                    >
                      MAKE OFFER
                    </Button>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Button variant="outline-primary" className={`mt-3 ${styles.backgroundGradient}`}>
                  HISTORY
                </Button>
                <Card className="mt-4 p-3">
                  {data?.history.map((item, index) => (
                    <div key={index} className="d-flex gap-2 mb-2">
                      <Image
                        fluid
                        style={{ width: "56px", height: "56px" }}
                        src={item.image}
                        className="rounded-circle"
                      />
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ flexBasis: "100%" }}
                      >
                        <div>
                          <div className="fw-bold">{item.address} listed for</div>
                          <div className="small fw-bold text-muted">
                            {item.datetime}
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <div className="fw-bold">{item.cryptoPrice} MATIC</div>
                          <div className="small">${item.price}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Card>
              </Col>
            </Row>
          </div>
        )}
        {showReportModal ? (
          <ReportNftModal
            show={showReportModal}
            // id={collection_slug}
            onClose={() => setShowReportModal(false)}
          ></ReportNftModal>
        ) : null}
        {showOfferModal ? (
          <OfferModal
            show={showOfferModal}
            onClose={() => setShowOfferModal(false)}
            category={data?.category}
            title={data?.title}
          ></OfferModal>
        ) : null}
      {/* </div> */}
    </LandingPageLayout>
  )
}
