import React, { useEffect } from "react"
import { Dropdown, Image } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import OneSignal from "react-onesignal"
import InlineLoader from "../../generic/Loaders/InlineLoader"
import { main_api } from "../../../utils/axios_helper"
import styles from "./Notifications.module.scss"
import { is } from "date-fns/locale"

export default function Notifications({
  notificationsData,
  setNotificationsData,
  notificationsProcessing,
  setReceivedNotification
}) {
  useEffect(() => {
    OneSignal.Notifications.addEventListener(
      "foregroundWillDisplay",
      foregroundWillDisplayListener
    )
    return () => {
      OneSignal.Notifications.removeEventListener(
        "foregroundWillDisplay",
        foregroundWillDisplayListener
      )
    }
  }, [])

  const foregroundWillDisplayListener = event => {
    setReceivedNotification(true)
  }

  const clearAllHandler = async () => {
    try {
      setNotificationsData([])
      const response = await main_api.patch(
        "/api/v1/notifications/mark-as-clear/"
      )
    } catch (error) {
      throw new Error(error)
    }
  }

  const markAllAsReadHandler = async () => {
    try {
      const response = await main_api.patch(
        "/api/v1/notifications/mark-as-read/"
      )
    } catch (error) {
      throw new Error(error)
    }
  }

  const markAsReadHandler = async id => {
    try {
      const response = await main_api.patch(`/api/v1/notifications/${id}/`, {
        is_read: true
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  return (
    <Dropdown.Menu style={{ width: "254px" }}>
      <div className="d-flex mx-3 justify-content-between">
        <small role="button" onClick={clearAllHandler}>
          Clear All
        </small>
        <small role="button" onClick={markAllAsReadHandler}>
          Mark as read
        </small>
      </div>

      {/* Display message if no new notifications */}
      {!notificationsProcessing && notificationsData?.length === 0 && (
        <div className="p-5 text-center text-muted">
          No new notifications
        </div>
      )}

      {/* Display loader while processing notifications */}
      {notificationsProcessing && (
        <div className="text-center">
          <InlineLoader />
        </div>
      )}

      {/* Render each notification */}
      <div className={styles.notificationScroll}>
        {!notificationsProcessing &&
          notificationsData?.map((notification, index) => (
            <Dropdown.Item key={index} as="button">
              <Link
                className="d-flex gap-2 text-decoration-none"
                to={`/${notification.type + "s"}/${
                  notification.metadata[notification.type]
                }`}
                onClick={() => markAsReadHandler(notification.id)}
              >
                <FontAwesomeIcon icon={faBell} color="blue" />
                <div>
                  <Image
                    src="https://www.w3schools.com/bootstrap4/img_avatar3.png"
                    alt="event"
                    fluid
                    className="rounded-circle"
                    width={25}
                    height={25}
                  />
                  <div className="text-wrap">{notification?.title}</div>
                  <div className="text-muted text-wrap">
                    {notification?.message}
                  </div>
                </div>
                {!notification.is_read && <div className={styles.unread}></div>}
              </Link>
            </Dropdown.Item>
          ))}
      </div>
    </Dropdown.Menu>
  )
}
