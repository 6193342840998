import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  events: {},
  totalAmount: 0,
  totalQuantity: 0
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addTicket: (state, action) => {
      const {
        eventId,
        ticketType,
        ticketPrice,
        seatId,
        quantity = 1
      } = action.payload

      if (!state.events[eventId]) {
        state.events[eventId] = {
          eventId,
          tickets: {},
          price: 0
        }
      }

      if (!state.events[eventId].tickets[ticketType]) {
        state.events[eventId].tickets[ticketType] = {
          ticketPrice,
          seats: []
        }
      }

      state.events[eventId].tickets[ticketType].seats.push(seatId)
      state.events[eventId].price += ticketPrice * quantity
      state.totalQuantity += quantity
      state.totalAmount += ticketPrice * quantity
    },
    removeTicket: (state, action) => {
      const {
        eventId,
        ticketType,
        ticketPrice,
        seatId,
        quantity = 1
      } = action.payload

      if (state.events[eventId] && state.events[eventId].tickets[ticketType]) {
        const seatIndex =
          state.events[eventId].tickets[ticketType].seats.indexOf(seatId)
        if (seatIndex !== -1) {
          state.events[eventId].tickets[ticketType].seats.splice(seatIndex, 1)
        }

        state.totalQuantity -= quantity
        state.totalAmount -= ticketPrice * quantity
        state.events[eventId].price -= ticketPrice * quantity

        if (state.events[eventId].tickets[ticketType].seats.length === 0) {
          delete state.events[eventId].tickets[ticketType]
        }

        if (Object.keys(state.events[eventId].tickets).length === 0) {
          delete state.events[eventId]
        }
      }
    },
    removeEventFromCart: (state, action) => {
      const { eventId, noOfSeats } = action.payload
      if (Object.keys(state.events).length === 1 && state.events[eventId])
        cartSlice.caseReducers.clearCart(state)
      else if (state?.events[eventId]) {
        state.totalAmount -= state.events[eventId].price
        state.totalQuantity -= noOfSeats
        delete state.events[eventId]
      }
    },
    clearCart: state => {
      state.events = {}
      state.totalAmount = 0
      state.totalQuantity = 0
    },
    updateTicketQuantity: (state, action) => {
      const { eventId, ticketType, quantity } = action.payload
      const event = state.events[eventId]
      const ticket = event.tickets[ticketType]

      if (ticket) {
        const difference = quantity - ticket.quantity
        state.totalQuantity += difference
        state.totalAmount += difference * ticket.ticketPrice
        ticket.quantity = quantity
      }
    }
  }
})

export const {
  addTicket,
  removeTicket,
  clearCart,
  updateTicketQuantity,
  removeEventFromCart
} = cartSlice.actions

export default cartSlice.reducer
