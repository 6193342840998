import React from "react";
import Form from "react-bootstrap/Form";

export default function FormFieldError({errors}) {
    if (errors && !Array.isArray(errors)) {
        return <Form.Control.Feedback
            type="invalid" as={`ul`} className={`list-unstyled d-block`}
        >
            <li>{errors}</li>
        </Form.Control.Feedback>;
    } else if (errors && Array.isArray(errors) && errors.length > 0) {
        return <Form.Control.Feedback
            type="invalid" as={`ul`} className={`list-unstyled d-block`}
        >
            {errors.map((item, index) => <li key={index}>{item}</li>)}
        </Form.Control.Feedback>;
    }
    return null;
}
