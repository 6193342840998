// src/components/NftCard.js
import React from 'react';
import styles from './NftCard.module.scss';

const NftCard = ({ nft }) => {
    return (
        <div className={styles.card} onClick={nft?.onClick}>
            <div
                className={styles.imgContainer}
                style={{ backgroundImage: `url(${nft.image})` }}
            ></div>
            <div className={styles.content}>
                <div className={styles.title}>{nft.name}</div>
                <p className={styles.description}>{nft.description}</p>
            </div>
            <div className={styles.tags}>
                <span className={styles.tag}>#{nft.id}</span>
            </div>
        </div>
    );
};

export default NftCard;
