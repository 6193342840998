import {createSlice} from '@reduxjs/toolkit'
import {hasValidApiToken} from "../utils/auth";

export const commonSlice = createSlice({
    name: 'common',
    initialState: {
        loggedIn: false,
        userData: null,
    },
    reducers: {
        updateLoginState: (state) => {
            state.loggedIn = hasValidApiToken()
        },
        updateUserData: (state, data) => {
            state.userData = data.payload
        },
    },
});

export const {updateLoginState, updateUserData} = commonSlice.actions;

export default commonSlice.reducer;
