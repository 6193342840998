import './App.css';
import {Route, Routes} from "react-router-dom";
import {lazy, useEffect} from "react";
import RequireLoggedOut from "./components/generic/RoutesWrappers/RequireLoggedOut";
import * as commonActions from "./redux/commonSlice";
import {useDispatch} from "react-redux";
import RequireLoggedIn from "./components/generic/RoutesWrappers/RequireLoggedIn";
import { routes } from './routes';
import CollectibleDetailPage from './pages/collectibles/[id]';
import mixpanel from 'mixpanel-browser';
import CollectibleListingPage from './pages/collectibles/collectible-listing';

const LandingPage = lazy(() => import('./pages'));

const CryptoWalletsPage = lazy(() => import('./pages/crypto-wallets'));

const EventsPage = lazy(() => import('./pages/events'));
const CreateEventPage = lazy(() => import('./pages/events/create'));
const CreateEventWithAiPage = lazy(() => import('./pages/events/createWithAi'));
const EventDetailPage = lazy(() => import('./pages/events/[id]'));

const CollectiblesPage = lazy(() => import('./pages/collectibles'));
const CreateCollectiblesPage = lazy(() => import('./pages/collectibles/create/index'));

const ManageVenuesPage = lazy(() => import('./pages/manage-venues'));
const CartPage = lazy(() => import('./pages/cart'));
const MyWallet = lazy(() => import('./pages/my-wallet'));
const AddMoneyPage = lazy(() => import('./pages/add-money'));
const AddCardPage = lazy(() => import('./pages/add-card'));
const AddNewPayment = lazy(() => import('./pages/add-new-payment'));
const CreateVenuePage = lazy(() => import('./pages/manage-venues/create'));
const EditVenuePage = lazy(() => import('./pages/manage-venues/edit'));

const SeatsSelectionPage = lazy(() => import('./pages/events/[id]/seats-selection'));

const MyEventTicketsPage = lazy(() => import('./pages/my-event-tickets'));
const EventTicketDetailPage = lazy(() => import('./pages/my-event-tickets/[id]'));

const SignUpPage = lazy(() => import('./pages/sign-up'));
const LoginPage = lazy(() => import('./pages/login'));
const ForgotPasswordPage = lazy(() => import('./pages/forgot-password'));
const ResetPasswordPage = lazy(() => import('./pages/reset-password'));
const TwoFactorVerificationPage = lazy(() => import(
    './pages/two-factor-verification'
));

const TermsPage = lazy(() => import('./pages/terms'));
const PrivacyPage = lazy(() => import('./pages/privacy-policy'));
const HelpPage = lazy(()=> import('./pages/help'))
const ManageEventsPage = lazy(() => import('./pages/manage-events'));
const EventsListingPage = lazy(() => import('./pages/events/listing'));
const PortfolioPage = lazy(() => import('./pages/portfolio'));
const EventPerformanceSummary = lazy(() => import('./pages/dashboard'));
const PageView = lazy(() => import('./pages/dashboard/PageView'));
const TicketSale = lazy(() => import('./pages/dashboard/TicketSale'));
const MyCollectiblesPage = lazy(() => import('./pages/my-collectibles'));

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        mixpanel.init("a589525eb75b67f9b691b474d4c452e4");
        dispatch(commonActions.updateLoginState());
    }, [])

    return <Routes>
        <Route
            path=""
            element={<LandingPage/>}
        />

        <Route
            path="events/listing"
            element={<EventsListingPage/>}
        />

        <Route
            path="crypto-wallets"
            element={<RequireLoggedIn><CryptoWalletsPage/></RequireLoggedIn>}
        />

        <Route
            path="events"
            element={<EventsPage/>}
        />
        <Route
            path="events/create"
            element={<RequireLoggedIn><CreateEventPage/></RequireLoggedIn>}
        />
        <Route
            path="events/create-with-ai"
            element={<RequireLoggedIn><CreateEventWithAiPage/></RequireLoggedIn>}
        />
        <Route
            path="events/:id"
            element={<EventDetailPage/>}
        />

        <Route
            path="events/:id/seats-selection"
            element={<RequireLoggedIn><SeatsSelectionPage/></RequireLoggedIn>}
        />

        <Route
            path="my-collectibles"
            element={<RequireLoggedIn><MyCollectiblesPage/></RequireLoggedIn>}
        />

        <Route
            path="my-collectibles/:id"
            element={<RequireLoggedIn><MyCollectiblesPage/></RequireLoggedIn>}
        />

        <Route
            path="collectibles"
            element={<CollectiblesPage/>}
        />

        <Route
            path="collectibles/:id"
            element={<CollectibleDetailPage/>}
        />

        <Route
            path="collectibles/listing/:slug"
            element={<CollectibleListingPage/>}
        />

        <Route
            path="collectibles/create"
            element={<RequireLoggedIn><CreateCollectiblesPage/></RequireLoggedIn>}
        />

        <Route
            path="my-event-tickets"
            element={<RequireLoggedIn><MyEventTicketsPage/></RequireLoggedIn>}
        />
        <Route
            path="my-event-tickets/:id"
            element={<RequireLoggedIn><EventTicketDetailPage/></RequireLoggedIn>}
        />

        <Route
            path="manage-venues"
            element={<RequireLoggedIn><ManageVenuesPage/></RequireLoggedIn>}
        />
        <Route
            path={routes.myWallet}
            element={<RequireLoggedIn><MyWallet/></RequireLoggedIn>}
        />
        <Route
            path="manage-venues/create"
            element={<RequireLoggedIn><CreateVenuePage/></RequireLoggedIn>}
        />
        <Route
            path="manage-venues/:id"
            element={<RequireLoggedIn><EditVenuePage/></RequireLoggedIn>}
        />

        <Route
            path="sign-up"
            element={<RequireLoggedOut><SignUpPage/></RequireLoggedOut>}
        />
        <Route
            path="login"
            element={<RequireLoggedOut><LoginPage/></RequireLoggedOut>}
        />
        <Route
            path="forgot-password"
            element={<RequireLoggedOut><ForgotPasswordPage/></RequireLoggedOut>}
        />
        <Route
            path="reset-password/:token"
            element={<RequireLoggedOut><ResetPasswordPage/></RequireLoggedOut>}
        />
        <Route
            path="two-factor-verification/:token/:remember"
            element={<RequireLoggedOut><TwoFactorVerificationPage/></RequireLoggedOut>}
        />
       
        <Route
            path="terms"
            element={<TermsPage/>}
        />
        <Route
            path="privacy-policy"
            element={<PrivacyPage />}
        />

        <Route
            path="portfolio"
            element={<PortfolioPage />}
        />

        <Route
            path="manage-events"
            element={<RequireLoggedIn><ManageEventsPage /></RequireLoggedIn>}
        />
        
        <Route
            path="cart"
            element={<RequireLoggedIn><CartPage /></RequireLoggedIn>}
        />

        <Route
            path="/dashboard"
            element={<RequireLoggedIn><EventPerformanceSummary /></RequireLoggedIn>}
        />

        <Route
            path="/event-ticket-sales"
            element={<RequireLoggedIn><TicketSale /></RequireLoggedIn>}
        />

        <Route
            path="/event-page-views"
            element={<RequireLoggedIn><PageView /></RequireLoggedIn>}
        />

        <Route
            path={routes.addMoney}
            element={<RequireLoggedIn><AddMoneyPage /></RequireLoggedIn>}
        />
        <Route
            path={routes.addNewPayment}
            element={<RequireLoggedIn><AddNewPayment /></RequireLoggedIn>}
        />
        <Route
            path={routes.addCard}
            element={<RequireLoggedIn><AddCardPage /></RequireLoggedIn>}
        />
        <Route
            path={routes.help}
            element={<RequireLoggedIn><HelpPage /></RequireLoggedIn>}
        />
        {/*<Route path="*" element={<NotFoundPage/>}/>*/}
    </Routes>;
}

export default App;
