import React from 'react';
import {Navigate} from 'react-router-dom';
import {LOGGED_IN_LANDING_ROUTE} from "../../../utils/constants";
import {hasValidApiToken} from "../../../utils/auth";

export default function RequireLoggedOut({children}) {
    const isLoggedIn = hasValidApiToken();
    if (isLoggedIn) {
        return <Navigate to={LOGGED_IN_LANDING_ROUTE} replace={true}/>;
    } else {
        return children;
    }
}
