import {Store} from "react-notifications-component";

export const showNotification = (title, message, type = "success") => {
    Store.addNotification({
        container: "top-left",
        type: type,  // success, danger, info, default, warning
        title: title,
        message: message,
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true,
            pauseOnHover: true,
            timingFunction: 'ease-out',
            showIcon: true,
        }
    });
}
