// src/utils/openseaClient.js

import axios from 'axios';

const API_KEY = '03b6cce2a3d946a196e120c04a918490';  // Replace with your actual API key
const BASE_URL = 'https://testnets-api.opensea.io/api/v2';

const openseaClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'X-API-KEY': API_KEY,
    'Accept': 'application/json'
  }
});

export const CHAIN = "sepolia";

export default openseaClient;
