import React, { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import FormFieldError from "../ValidationMessages/FormFieldError";
import showPassWordIcon from "../../../../src/assets/showpassword.svg"
import hidePassWordIcon from "../../../../src/assets/hidepassword.svg"

const FloatingInput = ({ id, label, disabled, errors, name, type = "text", placeholder = "", helpText, wrapperClassName, inputRef, ...props }) => {
    const [hidepassword, setHidePassword] = useState(true)
    
    const typeExtension = type === 'password' ? hidepassword == true ? type : 'text' : type

    return (
        <FloatingLabel
            controlId={id}
            label={label}
            className={wrapperClassName || "mt-3 position-relative"}
        >
            <Form.Control
                className={type === 'password' ? "pe-5" : 'pe-3'}
                type={typeExtension}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                isInvalid={!!errors}
                ref={inputRef}
                {...props}
            />
            {helpText && <Form.Text className={`fs-_75x ps-_75x`}>
                {helpText}
            </Form.Text>}
            <FormFieldError errors={errors} />
            {type === 'password' && <div className="position-absolute ms-5" onClick={() => setHidePassword(!hidepassword)} style={{ top: '18px', right: '18px', cursor: 'pointer' }}>
                {hidepassword ? <img style={{ width: '20px', height: '20px' }} src={hidePassWordIcon} />
                    : <img style={{ width: '20px', height: '20px' }} src={showPassWordIcon} />
                }
            </div>
            }
        </FloatingLabel>
    )
}

export default FloatingInput