import {combineReducers, configureStore} from '@reduxjs/toolkit';
import commonReducer from "./commonSlice";
import cartSlice from './cartSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['cart'],
};

const rootReducer = combineReducers({
    common: commonReducer,
    cart: cartSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
