import React, { useEffect, useRef, useState } from "react"

import { Modal, Button } from "react-bootstrap"

import { main_api } from "../../../utils/axios_helper"
import { showNotification } from "../../../utils/notifications_helper"

import FloatingInput from "../../generic/InputFields/FloatingInput"
import InlineLoader from "../../generic/Loaders/InlineLoader"

export default function ReportNftModal({ show, onClose, id }) {
  const [report, setReport] = useState("")
  const [processing, setProcessing] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleReportNft = async () => {
    setProcessing(true)

    sendReport().then(() => {
      setProcessing(false)
      onClose()
    })
  }

  const sendReport = () => {
    const payload = { reason: report, nft: id }
    return new Promise((resolve, reject) => {
      main_api
        .post("/nfts/api/report/", payload)
        .then(res => {
          showNotification(
            "You have reported this NFT!",
            "Report id: " + res.data.id
          )
          resolve(res.data.id)
        })
        .catch(error => {
          showNotification("Error reporting NFT!", error, "danger")
          reject(error)
        })
    })
  }

  return (
    <>
      <Modal show={show} onHide={onClose} size={"default"} centered>
        <Modal.Header closeButton>
          <Modal.Title>Report NFT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingInput
            id={"report-nft"}
            label={"Please Tell us what is wrong with this NFT"}
            type={"text"}
            name={"report-nft"}
            disabled={processing}
            required={true}
            as={"textarea"}
            onChange={e => {
              setReport(e.target.value)
            }}
            minLength={6}
            inputRef={inputRef}
          />
        </Modal.Body>

        <Modal.Footer>
          {processing ? (
            <InlineLoader />
          ) : (
            <Button
              variant="secondary"
              disabled={report.trim().length < 6 || processing}
              onClick={handleReportNft}
            >
              Report
            </Button>
          )}
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
