import React, { useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, Dropdown} from "react-bootstrap";
import styles from "./LandingPageLayout.module.scss";
import Logo from "../../../assets/logo.png";
import {ReactComponent as IcBell} from "../../../assets/ic-bell.svg";
import {ReactComponent as IcCart} from "../../../assets/ic-cart.svg";
import {ReactComponent as IcSilhouette} from "../../../assets/ic-silhouette.svg";
import CustomButton from "../../generic/CustomButton";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../../utils/auth";
import { routes } from "../../../routes";
import Notifications from "../notifications/Notifications";
import CreateEventModal from "../events/create/CreateEventModal";
import { main_api } from "../../../utils/axios_helper";


export default function LandingPageLayout({children}) {
    // const [userDataErrors, setUserDataErrors] = useState({});
    // const [userDataProcessing, setUserDataProcessing] = useState(false);

    const [notificationsData, setNotificationsData] = useState([])
    const [notificationProcessing, setNotificationProcessing] = useState(false)
    const [receivedNotification, setReceivedNotification] = useState(false)
    const [haveUnreadNotifications, setUnreadNotifications] = useState(false)
    const isLoggedIn = useSelector(store => store.common.loggedIn);
    // const userData = useSelector(store => store.common.userData);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartItemsQuantity = useSelector(store => store.cart.totalQuantity);    

    const [showCreateEventModal, setShowCreateEventModal] = useState(false)
    // useEffect(() => {
    //     if (!isLoggedIn) {
    //         dispatch(updateUserData(null));
    //         return;
    //     }
    //     if (!!userData) {
    //         return;
    //     }
    //     setUserDataErrors({});
    //     setUserDataProcessing(true);
    //     main_api.get(
    //         `/api/v1/users/self-summary/`
    //     ).then(response => {
    //         dispatch(updateUserData(response.data));
    //     }).catch(error => {
    //         setUserDataErrors(error.response.data);
    //     }).then(() => {
    //         setUserDataProcessing(false);
    //     })
    // }, [isLoggedIn, userData]);

    // if (userDataProcessing) {
    //     return <FullPageOverlayLoader/>;
    // } else if (Object.keys(userDataErrors).length > 0 || !userDataErrors) {
    //     return <>
    //         <ErrorBlock errors={userDataErrors.detail}/>
    //     </>;
    // }

    useEffect(() => {
        fetchNotificationHandler()
    }, [])

    const handleCreateEvent = () => { 
        setShowCreateEventModal(true)
    }

    const fetchNotificationHandler = async () => {
        setNotificationProcessing(true);
        try {
            const response = await main_api.get("/api/v1/notifications/");
            const data = response.data;
            setNotificationsData(data);
            setUnreadNotifications(data.some(notification => !notification.is_read));
        } catch (error) {
            console.error("Failed to fetch notifications", error);
        } finally {
            setNotificationProcessing(false);
        }
    }

    return <>
        <div className={`mwc`}>
            <div className={`d-flex align-items-center justify-content-between py-3`}>
                <div className={`d-flex align-items-center px-3`}>
                    <Link to={"/"}>
                        <img src={Logo} alt={"Tixies Logo"} className={`pe-3`}/>
                    </Link>

                    <Link
                        to={"/events"}
                        className={`btn btn-light border-0 px-_75x py-_625x fw-bold text-decoration-none ms-3`}
                    >BUY EVENT TICKETS</Link>
                    <Link
                        to={"/collectibles"}
                        className={`btn btn-light border-0 px-_75x py-_625x fw-bold text-decoration-none ms-3`}
                    >BUY NFT COLLECTIBLES</Link>
                    {/* <Link
                        to={"#"}
                        className={`btn btn-light border-0 px-_75x py-_625x fw-bold text-decoration-none ms-3`}
                    >BOOK AIRFARE AND HOTEL</Link>
                    <Link
                        to={"#"}
                        className={`btn btn-light border-0 px-_75x py-_625x fw-bold text-decoration-none ms-3`}
                    >TIXIES TRIBE</Link> */}
                </div>

                {isLoggedIn ?
                <div className={`d-flex align-items-center px-3`}>
                    <Dropdown onClick={fetchNotificationHandler}>
                        <Dropdown.Toggle
                            variant="outline-light"
                            id="notifications-dd"
                            className={`p-_75x position-relative ${styles.notificationBtn} ${(receivedNotification || haveUnreadNotifications) && styles.unread}`}
                        >
                            <IcBell/>
                        </Dropdown.Toggle>

                        <Notifications 
                            notificationsData={notificationsData}
                            setNotificationsData={setNotificationsData}
                            notificationsProcessing={notificationProcessing} 
                            setReceivedNotification={setReceivedNotification}
                        />
                    </Dropdown>
                    
                    <CustomButton
                        id="cart-btn"
                        variant={`outline-light`}
                        className={`p-_75x ms-3 position-relative ${styles.cartBtn}  ${styles.notificationBtn} ${cartItemsQuantity && styles.unread}`}
                        onClick={()=>navigate('/cart')}
                    >
                        <IcCart/>
                    </CustomButton>

                    <Dropdown align={"end"}>
                        <Dropdown.Toggle
                            variant="none"
                            id="user-dd"
                            className={`p-0 border-0 ms-3 ${styles.userBtn}`}
                        >
                            <IcSilhouette/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                as={Link}
                                to={routes.dashboard}
                                className={`fw-bold`}
                            >
                                Dashboard
                            </Dropdown.Item>   
                            <Dropdown.Item
                                as={Link}
                                to={routes.portfolio}
                                className={`fw-bold`}
                            >
                                Portfolio
                            </Dropdown.Item>                        
                            <Dropdown.Item
                                as={Link}
                                to={routes.myWallet}
                                className={`fw-bold`}
                            >
                                My Wallet
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to={"/my-event-tickets"}
                                className={`fw-bold`}
                            >
                                My Event Tickets
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to={"/my-collectibles"}
                                className={`fw-bold`}
                            >
                                My Collectibles
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to={`/manage-venues`}
                                className={`fw-bold`}
                            >
                                Manage Venues
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to={`/manage-events`}
                                className={`fw-bold`}
                            >
                                Manage Events
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to={'/help'}
                                className={`fw-bold`}
                            >
                                Help
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={"button"}
                                className={`fw-bold`}
                                onClick={() => {
                                    logoutUser(dispatch, navigate);
                                }}
                            >
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                        <Dropdown.Toggle
                            variant="light"
                            id="create-dd"
                            className={`p-_75x ms-3 fw-bold ${styles.createBtn}`}
                        >
                            CREATE
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                as={'button'}
                                className={`dropdown-item fw-bold`}
                                onClick={handleCreateEvent}
                            >
                                Create Event
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={"button"}
                                className={`fw-bold`}
                                onClick={() => navigate('/collectibles/create')}
                            >
                                Create Collectibles-NFT
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                :
                <Button
                    variant="light"
                    className={`p-_75x ms-3 fw-bold ${styles.createBtn}`}
                    onClick={() => navigate('/login')}
                >
                    Login
                </Button>
                }
            </div>
        </div>
        {children}
        <div
            className={`mwc mt-9x fs-5 fw-bold color-black-60 border-top d-flex align-items-center justify-content-center py-5`}
        >
            <div>&copy; Tixies Inc. All rights reserved.</div>
            <Link to={'/terms'} className={`color-black-60 text-decoration-none mx-5`}>Terms & Conditions</Link>
            <Link to={'/privacy-policy'} className={`color-black-60 text-decoration-none`}>Privacy Policy</Link>
        </div>

        {
            showCreateEventModal&&<CreateEventModal show={showCreateEventModal} onClose={()=>setShowCreateEventModal(false)}></CreateEventModal>
        }
    </>;
}