import Cookies from "js-cookie";
import {updateLoginState} from "../redux/commonSlice";
import {showNotification} from "./notifications_helper";
import {DEFAULT_LANDING_ROUTE} from "./constants";

export const hasValidApiToken = () => {
    return !!getApiToken();
};

export const setApiToken = (token, dispatch, remember = false) => {
    const options = {};
    if (remember) {
        options['expires'] = 30;
    }
    Cookies.set('tx_api_token', token, options);
    dispatch(updateLoginState());
};

export const getApiToken = () => {
    return Cookies.get('tx_api_token');
};

export const removeApiToken = (dispatch) => {
    Cookies.remove('tx_api_token');
    dispatch(updateLoginState());
};

export const logoutUser = (dispatch, navigate) => {
    if (hasValidApiToken()) {
        removeApiToken(dispatch);
        showNotification(
            "Logged out!", "You're now logged out!", "info"
        );
    }
    navigate(DEFAULT_LANDING_ROUTE);
}