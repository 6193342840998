import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {ReactNotifications} from "react-notifications-component";
import FullPageOverlayLoader from "./components/generic/Loaders/FullPageOverlayLoader";
import {Provider} from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading ={null} persistor={persistor}>
            <ReactNotifications/>
            <BrowserRouter>
                <Suspense fallback={<FullPageOverlayLoader/>}>
                    <App/>
                </Suspense>
            </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
