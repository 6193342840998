import React, { useState } from "react"

import { Modal, Button, Card, Row, Col } from "react-bootstrap"

import MetaMask from "../../../assets/metamask.svg"

import FloatingInput from "../../generic/InputFields/FloatingInput"
import CustomButton from "../../generic/CustomButton"

export default function OfferModal({ show, onClose, category, title }) {
  const [processing, setProcessing] = useState(false)
  const text = "0xadd55Fdf35cA201C0d5A13F0bB5AeD4AED94826c"
  const connected = true

  const handleMakeOffer = () => {
    setProcessing(true)
    console.log("offer made")
    setProcessing(false)
    onClose()
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={onClose}
        // style={{
        //   display: "flex !important",
        //   maxWidth: "400px",
        //   position: "fixed",
        //   top: "50%",
        //   left: "50%",
        //   transform: "translate(-50%, -50%)"
        // }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="fw-bold">Make Offer</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            Your are about to make an offer for{" "}
            <span className="fw-bold">{title}</span> from{" "}
            <span className="fw-bold">{category}</span>
          </div>
          <Card border="dark" className="mb-3">
            <div className="d-flex align-items-center justify-content-evenly">
              <div
                className="overflow-hidden"
                style={{
                  height: "64px",
                  width: "64px",
                  borderRadius: "50%"
                }}
              >
                <img className="w-100 h-100" src={MetaMask} alt="MetaMask" />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p
                  className="mb-0"
                  style={{
                    color: "#212121",
                    fontWeight: 700,
                    fontSize: "20px",
                    lineHeight: "18px"
                  }}
                >
                  MetaMask
                </p>
                <p
                  className="mb-0 pt-2"
                  style={{
                    color: "#666666",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "18px"
                  }}
                >
                  {text.slice(0, 10) + "..." + text.slice(-5)}
                </p>
              </div>
              <div>
                {connected ? (
                  <p
                    style={{
                      color: "green",
                      backgroundColor: "lightgreen",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "10px",
                      fontWeight: "bold"
                    }}
                  >
                    {" "}
                    Connected
                  </p>
                ) : (
                  <p
                    style={{
                      color: "red",
                      backgroundColor: "lightpink",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "10px",
                      fontWeight: "bold"
                    }}
                  >
                    {" "}
                    Not Connected
                  </p>
                )}
              </div>
            </div>
          </Card>
          <div className="mb-5">
            <h4>Your Offer</h4>
            <FloatingInput
              id={"nft_offer"}
              label={"Enter price"}
              type={"number"}
              name={"nft_offer"}
              disabled={processing}
              required={true}
            />
          </div>

          <div>
            <Row>
              <Col>Your Bidding balance</Col>
              <Col className="fw-bold text-end">0 Matric</Col>
            </Row>
            <Row>
              <Col>Your balance</Col>
              <Col className="fw-bold text-end">0 Matric</Col>
            </Row>
            <Row>
              <Col>Service Fee</Col>
              <Col className="fw-bold text-end">0 Matric</Col>
            </Row>
          </div>
          <div>
            <Row>
              <hr
                className="mb-1 mt-1"
                style={{ borderTop: "1px solid rgba(0,0,0,0.5)" }}
              />
            </Row>
            <Row>
              <Col>You Will Pay</Col>
              <Col className="fw-bold text-end">0 Matric</Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <CustomButton variant="secondary" onClick={handleMakeOffer}>
            Make Offer
          </CustomButton>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
