import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {LOGIN_PAGE_ROUTE} from "../../../utils/constants";
import {hasValidApiToken} from "../../../utils/auth";
import {showNotification} from "../../../utils/notifications_helper";

export default function RequireLoggedIn({children}) {
    const isLoggedIn = hasValidApiToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            // This is called twice on dev only, due to React.StrictMode
            // https://stackoverflow.com/a/61897567/3341737
            showNotification(
                "Login Required!",
                "You must be logged in to view this page.",
                "info"
            );
            navigate(LOGIN_PAGE_ROUTE, {replace: true});
        }
    }, [isLoggedIn]);

    if (isLoggedIn) {
        return children;
    } else {
        return null;
    }
}
